.footer{
    background-color: #141414;
    color: white;
    padding: 0;
    text-align: center;
    width: 100%;
    margin-top: 16px;

}
.colunasFooter{
    display: flex;
    text-align: justify;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
}
.colunasFooter > div{
    width: 25%;
    padding: 16px;
}
.colunasFooter > div >h4{
    text-align: center;
}
.sobreFooter{
    text-indent: 30px;
}

/* src/Footer.css */
  
  .colunasFooter > div {
    width: 25%;
    padding: 10px;
  }
  
  h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  
  .toggle-icon {
    display: none;
  }
  
  .content {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
  }
  
  .content.expanded {
    display: block;
  }
  .imagemFooter{
    text-align: center;
  }
  .icon {
    margin-right: 10px;
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    /*width: 24px;  /* Ajuste conforme necessário */
    /*height: 24px; /* Ajuste conforme necessário */
    background-color: white;
    border-radius: 50%;
    margin: 10px; /* Ajuste conforme necessário */
  }
  .circle > svg{
    padding: 8px;
  }
  .linksFooter > li > a{
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
  }
  .listaFooter > li{
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }
  .phone-icon {
    color: black;
    font-size: 14px; /* Ajuste conforme necessário */
  }
  @media (max-width: 800px) {
    .linksFooter > li > a{
        width: 96%;
      }
    h4{
        justify-content: space-between;
    }
    .footer{
        padding: 0;
    }
    .colunasFooter{
        flex-wrap: wrap;
    }
    .colunasFooter > div {
      width: 100%;
    }
  
    .toggle-icon {
      display: inline;
    }
  
    .content {
      max-height: 0;
    }
  
    .content.expanded {
      max-height: 500px; /* Ajuste conforme necessário */
    }
  }
  