.infoProduto{
  background-image:url("../../../public/imagens/fundosf.png");
  width: 21.5vw;
  height: 30vw;
  position:relative;
  background-size: cover; /* Faz com que a imagem cubra todo o card */
  background-position: center;
  font-weight: 600;
}
.fundoPreto{
  bottom:0px;
  width: 100%;
  height: 35%;
  background: linear-gradient(0deg, #000000 30%, #000000e8 50%, #000000ba 80%, #00000000 100%);
  z-index:1;
  position:absolute;
}
.nomeProduto{
  color:#fff;
  font-size:2vw;
  text-align:center;
  padding-top: 2%;
}
.precoProduto{
  
  color:#fff;
  padding: 8%;
  font-size:3vw;
  position:absolute;
  bottom:0px;
}
.imagemProduto{
  width: 100%;
  height: 100%;
}
.noPix{
  font-size: 1vw;
  margin-left: 1vw;
}
@media (max-width: 578px) {
  .infoProduto{
    background-image:url("../../../public/imagens/fundosf.png");
    width: 28.5vw;
    height: 40vw;
  }
  
  .precoProduto{
    
    font-size: 4vw;
  }
  .precoProduto span{
    
    font-size: 1vw;
  }
  .nomeProduto{
    
    font-size: 3vw;
  }
}
@media (max-width: 450px) {
  .infoProduto{
    background-image:url("../../../public/imagens/fundosf.png");
    width: 42.5vw;
    height: 59.6vw;
  }
  .precoProduto{
    
    font-size: 6vw;
  }
  .precoProduto span{
    
    font-size: 2vw;
  }
  .nomeProduto{
    
    font-size: 4vw;
  }
}