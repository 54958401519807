.pay-button-getnet, .pay-button{
    background-color: #212121;
    height: 42px;
    width: 200px;
    border-radius: 20px;
    font-size: large;
    display: flex;
    color: #ffffff;
    align-items: center;
    justify-content: center;

}
.botaoPagar{
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 16px;
}