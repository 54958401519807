.tituloCarrinho{
    color: #000;
    font-size: 2.625rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: oswald, Verdana, Geneva, sans-serif;
    margin: 40px 0;

}
.cardCarrinho{
    padding: 0 min(64px, 4%);
}
.carrinhoInfo{
    display: flex;
}
.listaProdutosCarrinho{
    display: block;
    width: 68%;
    text-align: left;
    font-size: 1em;
}
.produtoCarrinho{
    margin-bottom: 16px;
    min-height: 128px;
    position: relative;
    display: flex;
    grid-template-columns: repeat(12, 1fr);
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 .5px 4px 0 rgba(0, 0, 0, .16);
    background-color: #fff;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.resumoCarrinho{
    border-radius: 4px;
    box-shadow: 0 .5px 4px 0 rgba(0, 0, 0, .16);
    background-color: #fff;
    display: flex;
    width: 32%;
    flex-flow: column;
    margin-left: 16px;
    min-width: 277px;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    height: max-content;
    align-items: center;
    font-weight: 600;
    flex-direction: column;
}
.linhaResumoCarrinho{
    display: flex;
    flex-flow: column;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    height: max-content;
    align-items: center;
    
    margin-bottom: 8px; 
    width: 90%;

}
.separador{
    width: 90%;
    margin: 0 auto;
    opacity: .1;
    height: 0px;
    margin-bottom: 8px;
    padding: 5px 0;
    border-bottom: 2px solid #000;
    clear: both;
}
.totalItensCarrinho{
    margin-right: 4px;
}
.quantidadeBotao{
    width: 36px;
    height: 40px;
    background: #f3f2f2;
    border: 2px solid #e0e0e0;
    color: #999;
    margin: 0 -2px 0 -2px;
    box-sizing: border-box;
}
.inputQuantidade{
    width: 100%;
    height: 33.8px;
    width: 33px;
    text-align: center;
    border: 2px solid #e0e0e0;
    border-radius: 0;
    font-size: 1rem;
}
.inputQuantidade:active{
    border: 2px solid #e0e0e0;
}
.quantidadeCarrinho{
    display: flex;
    align-items: center;
    justify-content: center;
}
.imagemProdutoCarrinho{
    width: 140px;
}
.valorProdutoCarrinho{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: larger;
    font-weight: 600;
}
.observacaoProdutoCarrinho{
    font-weight: 200;
}
.col2Produto{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
    width: 100%;
}
@media (max-width: 900px) {
    .carrinhoInfo{
        
        flex-direction: column;
        align-items: center;
    }
    .listaProdutosCarrinho{
        width: 100%;
    }
    .resumoCarrinho{
        width: 100%;
        margin-left: 0;
    }
    .imagemProdutoCarrinho{
        width: 100px;
    }
  }
  .comprar{
    flex: unset;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    justify-content: center;
    width: 90%;
    background-color: #008000;
    border-color: transparent;
    color: #fff;
    box-shadow: 0 0 0 0 #fff;
    cursor: pointer;
    border-radius: 6px;
    display: inline-block;
    font-family: Proxima Nova, -apple-system, Roboto, Arial, sans-serif;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    margin: 32px 0;
}
.semProduto{
    text-align: center;
    margin: 120px 0px;

}
.semProduto a{
    color: black;
    font-size: 16px;
}
.valorTotal{
    font-size: larger;
    font-weight: 700;
}
