/* src/index.css (ou qualquer outro arquivo CSS global) */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.listaItens{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.listaItens > li{
    margin: 8px;
}
