.listaProdutoFinalizacao{
    flex-direction: column;
    display: flex;
    align-content: flex-start;
    align-items: stretch;
}
.informacoesCompra{
    display: block;
    width: 68%;
    text-align: left;
    font-size: 1em;
    border-radius: 4px;
    box-shadow: 0 .5px 4px 0 rgba(0, 0, 0, .16);
    background-color: #fff;
    padding: 16px;
    font-size: larger;
}
.separadorTotal{
    width: 100%;
}
.informacoesCompra input{
    width: calc(100% - 32px);
}
.informacoesCompra > div{
    margin-bottom: 8px;
}
.botaoFinalizar{
    width: 90%;
    height: 42px;
    margin-bottom: 16px;
    border:none;
    border-radius:20px
}
.divBotoesPagamento{
    margin-top: 16px;
    width: 100%;
    text-align: center;

}
@media (max-width: 900px) {
    .informacoesCompra {
        width: calc(100% - 32px);
        padding: 0;
        margin: 8px;
        padding: 16px;
    }
}