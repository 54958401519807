/* src/App.css */
body {
  font-family:Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  background-color: #f8f8f8;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
}
.oculto{
  display: none;
}
a{
  text-decoration: none;
  color: white;
}
nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 10px 0;
  bottom: 0;
  width: 100%;
}
/* src/Footer.css */

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

main {
}

.siteManutencao{
  text-align: center;
  margin: 16vw;
}
button{
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  color: #000;
}

.modal > form > div > input{
  width: calc(100% - 1.5rem);
}
.modal-buttons button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons button:hover {
  background-color: #ddd;
}