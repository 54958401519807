/* src/Header.css */

.header {
    background-color: black;
    color: white;
    padding: 10px;
    padding-left: 64px;
    padding-right: 64px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-bottom: solid 5px red;
  }
  
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1312px;
    width: 100%;
    border-bottom: solid 1px #bdbdbdbd;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .search-cart-login {
    display: flex;
    align-items: center;
  }
  
  .search-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .search-icon {
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: gray;
  }
  
  .search {
    padding: 5px 30px 5px 5px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
  }
  
  .icon {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .header-bottom {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    width: 100%;
    max-width: 1310px;
  }
  
  .nav-item {
    font-weight: 600;
    text-decoration: none;
    color: white;
    padding: 5px 10px;
  }
  
  .nav-item:hover {
    background-color: gray;
    border-radius: 4px;
  }
  
  .header-mobile {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
  .whatsAppIcon{
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 9px 1px #000;
    z-index: 10;

  }
  .freteGratis{
    background-color: red;
    position: fixed;
    padding: 8px;
    width: 100%;
    top: 0px;
    font-weight: 600;
    z-index: 10;
  }
  .freteGratis > div {
    margin-right: 8px;
    margin-right: 46px;
  }
  .freteGratis > svg{
    right: 16px;
    position: fixed;
    top: 8px;
  }
  .marginFreteGratis{
    margin-top: 37px;
  }
  .qtdCarrinho{
    width: 20px;
    height: 20px;
    background: red;
    position: absolute;
    border-radius: 12px;
    top: -10px;
    right: 0px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .botaoCarrinho{
    position: relative;
  }
  @media (max-width: 800px) {
    .header-top,
    .header-bottom {
      display: none;
    }
    .header{
      padding-left: 8px;
      padding-right: 8px;
      
    border-bottom: solid 4px red;
    }
    .header-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  
    .header-mobile .title {
      flex-grow: 1;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
  
    .mobile-menu {
      position: fixed;
      top: 0;
      left: -250px;
      width: 250px;
      height: 100%;
      background-color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 16px;
      transition: left 0.3s ease;
      z-index: 100;
    }
  
    .mobile-menu.open {
      left: 0;
    }
  
    .mobile-menu .nav-item {
      margin: 10px 0;
    }
  
    .close-icon {
      
      position: absolute;
      top: 16px;
      right: 16px;
      align-self: flex-end;
      margin: 10px;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .esqueceuSenha{
    margin-top: 8px !important;
  }
  .formLogin{
    text-align: left;
  } 
  .formLogin div{
    margin-top: 16px;
  }
  .formLogin input{
    margin-top: 8px;
  }
  .formLogin a{
    margin-top: 8px;
    color: #212121;
  }
  .formLogin button{
    background: #414141;
    color: #fff;
    width: 100%;
    margin-top: 16px;
    height: 42px;
    border-radius: 22px;
  }

  .modalPopUp {
    height: 50%;
    width: 50%;
    text-align: center;
    color: #000;
    position: relative;
  }
  .tituloPromocao{
    color: #000;
    font-weight: 600;
    font-size: xx-large;
    padding-top: 16px;
  }
  .modalPopUp > a> img{
    width: 100%;
    height: auto;
  }
  .image-container {
    background: linear-gradient(161deg, /* Ângulo de 45 graus */ #ffffff 50%, /* Metade da área branca */ #000000 50% /* Metade da área preta */);
    border-radius: 24px;
    
    height: 100%;
  }
  .promo-image {
    width: 100%; /* Ajusta o tamanho conforme necessário */
    display: block;
  }
  .text-overlay {
    position: absolute;
    color: white; /* Cor do texto */
    font-size: 16px; /* Tamanho do texto */
    font-weight: bold;
    /* Adicione outros estilos aqui, como sombra de texto, alinhamento, etc. */
  }