.listaGabaritos{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px;
    color: #000;
}
.listaGabaritos > li > a {
    color: #000;
    display: inline-grid;
    text-align: center;
    font-weight: 700;
}
.listaGabaritos > li {
    margin: 8px;
}
.tituloPagina{
    margin: 16px;
    font-family: oswald, Verdana, Geneva, sans-serif;
}
.tituloPagina > div > a{
    color: #000;
}
.nomeTituloPagina{
    font-size: 52px;
    font-weight: bold;
}
.calculadora-conversao {
	text-align: center;
	margin-bottom: 45px;
	display: inline-block;
	width: 100%;
}
.calculadora-conversao .medidas-topo {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.calculadora-conversao .tdTitulo {
	text-align: center;
	color: #0056a7;
	line-height: 28px;
	margin: 0 0 10px 0;
	font-family: Open Sans Extrabold, sans-serif;
	-webkit-text-stroke: 0.3px;
	font-weight: 800;
	font-size: 18px;
	text-transform: uppercase;
}
.calculadora-conversao #tabela-conversao {
	float: left;
	width: 49%;
}
.calculadora-conversao .medida-original, .calculadora-conversao .medida-desejada {
	border: 1px solid #a19d9d;
	padding: 10px 10px 15px 10px;
	margin-bottom: 10px;
	background: #E8E8E8;
}
.calculadora-conversao .medida-original {
	margin-top: -5px;
}
.calculadora-conversao .medida-original .opcvalores, .calculadora-conversao .medida-desejada .opcvalores {
	width:30%;
	display:inline-block;
}
.calculadora-conversao .tabela-resultado {
	width: 100%;
	display: inline-block;
	margin-bottom: 0px;
}
.calculadora-conversao .tabela-resultado ul {
	padding: 0;
	text-align: center;
	display: inline-table;
	width: 100%;
	margin-bottom: 10PX;
}
.calculadora-conversao .tabela-resultado li {
	list-style: none;
	display: table;
	width: 100%;
	margin-bottom: 0;
}
.calculadora-conversao .tabela-resultado li.resultado-largura, .calculadora-conversao .tabela-resultado li.resultado-perfil {
	background: #f3f3f3;
}
.calculadora-conversao .tabela-resultado li .resultado-tab {
	display: inline-block;
	float: left;
	width: 25%;
	border: 1px solid;
	box-sizing: border-box;
	padding: 6px 2px;
}
.calculadora-conversao .tabela-resultado li .resultado-tab .diferenca-milimetro {
	display: inline-block;
}
.calculadora-conversao .tabela-resultado li .resultado-tab .diferenca-porcentagem {
	display: inline-block;
}
.calculadora-conversao .tabela-resultado li.resultado-tabela-head .resultado-tab {
	background: #005aab;
	border: 1px solid #005aab;
	color: #ffffff;
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
}
.calculadora-conversao .tabela-resultado-roda {
	float: right;
	width: 48%;
	position: relative;
}
.calculadora-conversao .bloco-img-original {
	display: inline-flex;
	margin-bottom: 40px;
	padding-top: 42px;
    width: 100%;
    opacity: 1;

}
.calculadora-conversao .pneu-original-diametro {
	background-image: url(../../../public/imagens/calculo-pneu-diametro.png);
	background-size: 100% 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-left: 1px solid #0056a7;
	border-right: 1px solid #0056a7;
	border-top: 2px dashed #0056a7;
	box-sizing: border-box;
	position: relative;
    width: 263.388px;
    height: 263.388px;
}
.calculadora-conversao .roda-original-diametro {
	background-image: url(../../../public/imagens/calculo-roda-diametro.png);
	background-size: 100% 100%;
	display: inline-block;
    width: 179.9px;
    height: 179.908px;

}
.calculadora-conversao .pneu-original-largura {
	background-image: url(../../../public/imagens/calculo-pneu-largura.png);
	background-size: 100% 100%;
	display: inline-block;
	border-left: 1px solid #0056a7;
	border-right: 1px solid #0056a7;
	border-top: 2px dashed #0056a7;
	box-sizing: border-box;
}
.calculadora-conversao .pneu-desejado-diametro {
	background-image: url(../../../public/imagens/calculo-pneu-diametro.png);
	background-size: 100% 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-left: 1px solid #0056a7;
	border-right: 1px solid #0056a7;
	border-top: 2px dashed #0056a7;
	box-sizing: border-box;
	position: relative;
}
.calculadora-conversao .roda-desejado-diametro {
	background-image: url(../../../public/imagens/calculo-roda-diametro.png);
	background-size: 100% 100%;
	display: inline-block;
}
.calculadora-conversao .pneu-desejado-largura {
	background-image: url(../../../public/imagens/calculo-pneu-largura.png);
	background-size: 100% 100%;
	display: inline-block;
	border-left: 1px solid #0056a7;
	border-right: 1px solid #0056a7;
	border-top: 2px dashed #0056a7;
	box-sizing: border-box;
}
.calculadora-conversao .bloco-img-desejado {
	display: inline-flex;
	padding-top: 42px;
}
.calculadora-conversao .img-desejado-diametro {
	width: 200px;
	padding-bottom: 200px;
	background: red;
	display: inline-block;
}
.calculadora-conversao .img-desejado-largura {
	width: 50px;
	height: 200px;
	background: red;
	display: inline-block;
	margin-right:30px;
}
.calculadora-conversao .tamanho-largura {
	margin: 0 auto;
	display: block;
}
.calculadora-conversao .info-resultado {
	padding: 12px;
	text-align: center;
	font-size: 17px;
	margin-bottom: 15px;
}
.calculadora-conversao .submit {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 25px;
}
.calculadora-conversao .submit button {
	background: #1b55a4;
	color: #ffffff;
	border: 1px solid #1b55a4;
	width: 250px;
	padding: 12px;
	font-size: 24px;
}
.calculadora-conversao .submit button:hover {
	background: #3165AC;
	color: #ffffff;
	border: 1px solid #3165AC;
}
.calculadora-conversao .perfil-original, .calculadora-conversao .perfil-desejado {
	border-left: 1px solid #0056a7;
	border-right: 1px solid #0056a7;
	border-top: 2px dashed #0056a7;
	box-sizing: border-box;
	position: absolute;
	left: -1px;
	top: -25px;
    width: 42.3981px;
    height: 288.388px;
}
.calculadora-conversao .texto-roda-original-diametro {
	position: absolute;
	top: -40px;
	white-space: nowrap;
    left: 50.7875px;
}
.calculadora-conversao .texto-roda-desejado-diametro {
	position: absolute;
	top: -25px;
	white-space: nowrap;
}
.calculadora-conversao .texto-perfil {
	position: absolute;
	top: -60px;
	left: 0;
	white-space: nowrap;
}
.calculadora-conversao .base-tamanho-largura {
	position: relative;
}

.calculadora-conversao .base-tamanho-largura {
	width: 50%;
}

.calculadora-conversao .tamanho-largura {
    width: 92.9236px;
    height: 263.388px;
}
.calculadora-conversao .texto-largura {
	position: absolute;
	top: -15px;
	left: 50%;
	transform: translate(-50%, -50%);
	white-space: nowrap;
}
.calculadora-conversao .tabela-resultado-roda .titulo-rodas {
}
.calculadora-conversao .tabela-resultado-roda h2 {
	text-align: left;
	color: #0056a7;
	line-height: 28px;
	margin: 0 0 20px 0;
	font-family: Open Sans Extrabold, sans-serif;
	-webkit-text-stroke: 0.3px;
	font-weight: 800;
	font-size: 18px;
	text-transform: uppercase;
	border-bottom: 1px solid #0056a7;
	padding-bottom: 10px;
}
.calculadora-conversao select {
	color: #676565!important;
	font-family: Open Sans Extrabold, sans-serif!important;
	font-weight: 800!important;
	font-size: 14px!important;
	text-transform: uppercase;
}
.widget-list-calculadora .owl-theme .owl-dots {
	display: none!important;
	;
}
.widget-list-calculadora .owl-theme .owl-nav {
	display: block!important;
	;
}
.calculadora-conversao .bloco-tamanho {
	position: relative;
    width: 45%;
    display: inline-block;
}
.calculadora-conversao .formulario{
    width: 45%;
    display: inline-block;
    margin-right: 5%;
}
.opcvalores input{
    width: 90%;
}
@media (max-width: 800px) {
    .calculadora-conversao .formulario{
        width: 90%;
    }
    .calculadora-conversao .bloco-tamanho{
        margin-top: 16px;
        width: 90%;
    }
}