.informacoesProduto{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
.colunaInformacoesProduto{
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 .5px 0 0 rgba(0, 0, 0, .16);
  background-color: #fff;
  align-items: center;
  width: 500px;
  position: relative;
  text-align: center;
  margin: 0 8px;
  padding: 16px;
}
.coluna2InformacoesProduto{
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 .5px 0 0 rgba(0, 0, 0, .16);
  background-color: #fff;
  align-items: center;
  width: 500px;
  position: relative;
  margin: 0 8px;
  padding: 16px;
}
.addqty{
  border: solid 1px rgba(0, 0, 0, .1);
  font-size: 16px;
  font-family: open_sans, Verdana, Geneva, sans-serif;
  border-radius: 3px;
  width: 80px;
  height: 48px;
  text-align: center;
  margin-right: 8px;
}
.addtocartbutton{
  background: #008000;
  color: #fff;
  font-family: oswald, Verdana, Geneva, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .8px;
  text-transform: uppercase;
  padding: 12px;
  border-style: none;
  border-radius: 3px;
  cursor: pointer;
  transition: .2s ease all;
  text-align: center;
  margin: 0;
  min-width: inherit;
  flex-grow: 1;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 50%);
}
.add_cart_area {
  display: flex;
  flex-direction: column;
  margin: 20px 0 30px;
}
.addToCardContainer {
  display: flex;
}
.formularioProduto{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
}
.formularioProduto select {
width: 100%;
}
.formularioProduto input {
  width: 100%;
  width: calc(100% - 1.5rem);
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    word-wrap: normal;
    
    background-repeat: no-repeat;
}
.descricaoProduto{
  color: #636262;
  text-align: justify;
}
.descricaoProduto a{
  color: #000;
  font-weight: 500;
}
.informacaoValor h1{
  display: flex;
  margin-bottom: 8px;
}
.informacaoValor h1 span{
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
}
.informacaoValor{
  margin: 32px 0px;
}
.botaoML{
  background: #ffe600;
  height: 52px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 25%);
}
.botaoML button{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: none;
  border: none;
}
.botaoML button img{
  height: 40px;
}

@media (max-width: 1000px) {
  .tituloCarrinho{
    margin: 8px 0;
  }
  .informacoesProduto{
    
    flex-direction: column;
  }
  .colunaInformacoesProduto, .coluna2InformacoesProduto{
    width: 100%;
    padding: 0;
    margin: 16px 0;
  }
  .coluna2InformacoesProduto > div{
    margin: 16px;

  }
  .imagemProdutoInformacaoes{
    width: 90%;
  }
}

.modal > p{
  
  text-align: justify;
  text-indent: 4em;
}
.modal > img {
  width: 250px;
}
.modal > span {
  float: right;
}
.indisponivel{
  margin-top: 16px;
  font-size: xxx-large;
  text-align: center;
}
.valorPix{
  color: red;
  font-size: 2em;
  font-weight: bold;
}